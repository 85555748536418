<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="cols">
        <v-card>
          <v-card-title>Signal</v-card-title>
          <signal-chart
              v-if="receiver.metrics.SignalLevel !== undefined"
              v-bind:signal="receiver.metrics.SignalLevel.history"
              v-bind:calls="receiver.metrics.ProviderName.history.events"
          />
        </v-card>
      </v-col>
      <v-col :cols="cols">
        <v-card>
          <v-card-title>SNR</v-card-title>
          <snr-chart
              v-if="receiver.metrics.Snr !== undefined"
              v-bind:snr="receiver.metrics.Snr.history"
              v-bind:snrMargin="receiver.metrics.SnrMargin.history"
              v-bind:calls="receiver.metrics.ProviderName.history.events"
          />
        </v-card>
      </v-col>
      <v-col
          v-for="card in cards[receiver.config.type]"
          :key="card.title"
          :cols="cols"
      >
        <v-card>
          <v-card-title v-text="card.title"></v-card-title>
          <v-list dense>
            <v-list-item
                v-for="metricName in card.items"
                v-bind:key="metricName"
                style="min-height: 30px"
            >
              <v-list-item-content>
                <span>
                  <b>{{ receiver.metrics[metricName].name }}:</b>
                </span>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-alert
                    v-if="invalidValue(metricName)"
                    type="error"
                    dense
                    class="ma-0 pa-1"
                >
                  {{ receiver.metrics[metricName].current_value }} {{ receiver.metrics[metricName].unit }} (moet zijn: {{receiver.metrics[metricName].valid_values[0]}})
                </v-alert>
                <span v-else>
                    {{ receiver.metrics[metricName].current_value }} {{ receiver.metrics[metricName].unit }}
                  </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            style="min-height: 364px"
        >
          <v-card-title>Last seen</v-card-title>
          <v-timeline
              dense
              class="overflow-y-auto"
              style="max-height: 300px"
          >
            <v-timeline-item
                v-for="event in call_log"
                :key="event.start"
                color="blue"
                small
                fill-dot
            >
              <strong>{{ event.value }}</strong>
              <div class="text-caption">
                start: {{ event.start | formatDate }}
              </div>
              <div class="text-caption" v-if="event.end !== 0">
                end: {{ event.end | formatDate }}
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignalChartComponent from "@/components/SignalChartComponent";
import SnrChartComponent from "@/components/SnrChartComponent";
import moment from 'moment';
import Vue from "vue";

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss')
  }
});

  export default {
    name: 'Receiver',
    components: {SnrChart: SnrChartComponent, SignalChart: SignalChartComponent},
    props: ["receiver"],
    methods: {
      invalidValue(metricName) {
        return !(this.receiver.metrics[metricName].valid_values===null || this.receiver.metrics[metricName].valid_values.includes(this.receiver.metrics[metricName].current_value))
      }
    },
    computed: {
      call_log () {
        let logs = this.receiver.metrics['ProviderName'].history?.events
        if (logs !== null)
          return logs.reverse()
        return null
      },
      cols () {
        console.log(this.$vuetify.breakpoint.name)
        // there are 12 cols on the screen, so return 3 means 4 cards per row
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 6
          case 'md': return 4
          case 'lg': return 4
          case 'xl': return 3
        }
        return 1
      },
    },
    data: () => ({
      cards: {
        "RX8200":
            [
              { title: 'RF', items: ["Frequency", "ReceiverStatus","SignalLevel","Snr","SnrMargin", "ModulationMode", "ModulationFormat", "SymRate", "FEC", "postViterbiBER"], flex: 3 },
              { title: 'Transport Stream', items: ["TsBitRate", "TsID", "NetworkID", "ProviderName", "ServiceID", "ServiceName", "PcrPid"], flex: 3 },
              { title: 'Video', items: ["VideoStatus", "VideoResolution", "AspectRatio", "VideoFrameRate", "VideoScanType", "VideoStandard", "VideoBitRate", "VideoPID"], flex: 3 },
              { title: 'Audio', items: ["AudioStatus", "AudioCoding", "AudioBitRate", "AudioSamplingFreq", "AudioPID"], flex: 3 },
            ],
        "TT1260":
            [
              { title: 'RF', items: ["ReceiverStatus","SignalLevel","Snr","SnrMargin", "ModulationFormat", "FEC"], flex: 3 },
              // { title: 'PsiSi', items: ["NetworkID", "ProviderName", "ServiceID", "ServiceName", "PcrPid"], flex: 3 },
              //{ title: 'Video', items: ["ModulationFormat"], flex: 3 },
              // { title: 'Audio', items: ["AudioStatus", "AudioPID", "AudioCoding", "AudioBitRate", "AudioSamplingFreq"], flex: 3 },
            ],
      },
    }),
  }
</script>
<style lang="scss">
.md-table-cell {
  height: 30px;
}

.md-table-cell#value {
  text-align: right;
}

/* https://getflywheel.com/layout/flexbox-create-modern-card-design-layout/ */
.v-row {
  margin: 4px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 20em) {
  .v-card {
    flex: 0 1 calc(100% - 1em);
  }
}

@media screen and (min-width: 40em) {
  .v-card {
    flex: 0 1 calc(50% - 1em);
  }
}

@media screen and (min-width: 60em) {
  .v-card {
    flex: 0 1 calc(33% - 1em);
  }
}

</style>