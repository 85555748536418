<template>
  <line-chart v-bind:options="options" v-bind:chartData="datasets" style="height: 300px"></line-chart>
</template>
<script>
import LineChartComponent from "@/components/LineChartComponent";

let index = 0

export default {
  components: {LineChart: LineChartComponent},
  props: ['snr', 'snrMargin', 'calls'],
  data () {
    return {
      options: {
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 30,
            }
          }],
          xAxes: [
            {
              id: 'time',
              type: 'time',
              position: 'bottom',
              distribution: 'linear',
              ticks: {
                minRotation: 45,
              },
              time: {
                stepSize: 30,
                displayFormats: {
                  second: "HH:mm:ss"
                },
              },
            },
            {
              id: 'calls',
              type: 'category',
              position: 'top',
              labels: this.snr?.map(item => { // going through time
                const now = new Date(item.Time)
                try {
                  if (new Date(this.calls[index].start) <= now && !(new Date(this.calls[index].end) < now)) { // we passed a call event
                    index += 1
                    return this.calls[index-1].value
                  }
                } catch (e) {
                  return ""
                }
                return ""
              }),
              ticks: {
                autoSkip: false,
              },
            },
          ],
          title: {
            display: false,
          }
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        // aspectRatio: 1,
      }
    }
  },

  computed: {
    datasets: function() {return {datasets: [
        {
          label: "SNR",
          backgroundColor: '#2c4df5',
          borderColor: '#2c4df5',
          fill: false,
          data: this.snr.map(item => {return {y: item.Value, x:item.Time}})
        },
        {
          label: "SNR margin",
          backgroundColor: '#e0e0e0',
          // borderColor: '#f52c2c',
          fill: 0,
          // data: this.snrMargin.map(item => {return {y: item.Value, x:item.Time}})
          data: this.snrMargin.map((item, index) => {
            // if (item.Value > this.snr[index].Value) {
            //   return {y:null, x:item.Time}
            // }
            try {
              return { y: this.snr[index].Value - item.Value, x:item.Time, orig:item.Value, snr:this.snr[index].Value }
            } catch (e) {
              return { y: 0, x: item.Time }
            }
          })
        },
      ]
    }}
  },
}
</script>