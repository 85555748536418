<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
<!--      FIXME: only show tabs on standalone. Hide them when we're under webcontrol. Detect by full url? -->
      <v-tabs
          background-color="primary"
          center-active
          dark
          icons-and-text
          v-model="tab"
          show-arrows
      >
        <v-tab href="#info">
          Info
          <v-icon>md-info</v-icon>
        </v-tab>
        <v-tab
            v-for="index in receivers.keys()"
            v-bind:href="'#'+receiverDescription(index)"
            v-bind:key="receiverDescription(index)"
        >
          {{ receiverDescription(index) }}
          <v-icon>{{receiverIcon(index)}}</v-icon>
        </v-tab>
     </v-tabs>
      <v-btn
          icon
          small
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
      >
        <v-icon>mdi-brightness-6</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-tabs-items v-model="tab">
        <v-tab-item value="info">
          <Guide/>
        </v-tab-item>

        <v-tab-item
            v-for="(receiver, index) in receivers"
            :key="receiverDescription(index)"
            :value="receiverDescription(index)"
        >
          <Receiver
              v-if="receiver.online"
              v-bind:receiver="receiver"
          />
          <div v-if="! receiver.online">
            <v-container fill-height fluid>
              <v-row align="center"
                     justify="center">
                <v-col>
                  <v-alert
                      elevation="10"
                      icon="mdi-alert"
                      type="error"
                  >
                    Deze ontvanger staat uit
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-app>
</template>

<script>
import ReceiverComponent from './components/ReceiverComponent';
import GuideComponent from "@/components/GuideComponent";

export default {
  name: 'App',

  components: {
    Guide: GuideComponent,
    Receiver: ReceiverComponent,
  },

  methods: {
    receiverIcon: function (receiver) {
      // wifi-strength-alert-outline
      if (this.receivers[receiver]?.online) {
        if (this.receivers[receiver]?.metrics?.ReceiverStatus?.current_value === "LOCKED") {
          // receiving valid signal
          return "mdi-wifi"
        } else {
          // idle
          return "mdi-wifi-strength-outline"
        }
      } else {
        // offline
        return "mdi-wifi-strength-alert-outline"
      }
    },
    receiverDescription: function (receiver) {
      return this.receivers[receiver]?.config?.description
    },
    async updateData() {
      // GET request using fetch with async/await
      const response = await fetch("json");
      const data = await response.json();
      if (data["error"]) {
        this.error = data["error"]
        return
      }
      this.receivers = data;
    },
  },

  async created() {
    await this.updateData();
    setInterval(this.updateData.bind(this), 1000)
  },

  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },

  data: () => ({
    receivers: []
  }),
};
</script>
